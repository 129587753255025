  .p-tabview .p-tabview-nav-content {
    scroll-padding-inline: 3rem;
  }
  .p-tabview .p-tabview-nav {
    background: transparent;
    border: solid rgba(255, 255, 255, 0.12);
    border-width: 0 0 1px 0;
  }
  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border: solid rgba(255, 255, 255, 0.12);
    border-width: 0 0 1px 0;
    /*border-color: transparent transparent rgba(255, 255, 255, 0.12) transparent;*/
    border-color: var(--gold);
    background: transparent;
    color: rgba(255, 255, 255, 0.6);
    padding: 1rem 1.5rem;
    font-weight: 500;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    transition: none;
    margin: 0 0 0 0;
  }
  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset none;
  }
  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: rgba(159, 168, 218, 0.04);
    /*border-color: rgba(255, 255, 255, 0.12);*/
    color: rgba(255, 255, 255, 0.6);
    border: 1px solid var(--gold);
    border-bottom: 0;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: transparent;
    /* border-color: rgba(255, 255, 255, 0.12); */
    border-color: var(--gold);
    color: var(--gold);
    border: 1px solid;
  }
  .p-tabview .p-tabview-left-icon {
    margin-right: 0.5rem;
  }
  .p-tabview .p-tabview-right-icon {
    margin-left: 0.5rem;
  }
  .p-tabview .p-tabview-close {
    margin-left: 0.5rem;
  }
  .p-tabview .p-tabview-nav-btn.p-link {
    background: transparent;
    color: var(--gold);
    width: 3rem;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0;
  }
  .p-tabview .p-tabview-nav-btn.p-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset none;
  }
  .p-tabview .p-tabview-panels {
    background: transparent;
    padding: 1rem;
    padding-top: 2px;
    border: 0 none;
    color: rgba(255, 255, 255, 0.87);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    flex: auto;
  }
