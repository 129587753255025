
.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: rgba(159, 168, 218, 0.32);
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #9FA8DA;
}
.p-progressbar .p-progressbar-label {
  color: #121212;
  line-height: 4px;
}

.p-progressbar,
.p-progressbar .p-progressbar-value {
  border-radius: 0.75rem;
  border: 1px solid #999;
}

