
.p-multiselect {
  border: .5px solid;
  border-bottom: 1.5px solid;
  border-color: var(--gold);
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}
.p-multiselect:not(.p-disabled):hover {
  border-color: rgba(255, 255, 255, );
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #9FA8DA;
}
.p-multiselect .p-multiselect-label {
  padding: .5rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  white-space: initial; /*adding this to make chips wrappable*/
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: rgba(255, 255, 255, 1);
}
.p-multiselect.p-multiselect-chip .p-multiselect-label {
  gap: 0.5rem;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.5rem 1rem;
  background: rgba(159, 168, 218, 0.16);
  color: #9FA8DA;
  border-radius: 4px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: var(--gold);
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.5rem 1rem;
}

.p-multiselect-panel {
  background: var(--glass-400);
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
  background: var(--glass-500);
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 2rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 1rem;
  color: rgba(255, 255, 255, 0.6);
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(255, 255, 255, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: rgba(255, 255, 255, 0.6);
  border-color: transparent;
  background: rgba(255, 255, 255, 0.04);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items {
  padding: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 1rem 1rem;
  border: 0 none;
  color: rgba(255, 255, 255, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #9FA8DA;
  background: var(--glass-800)
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(255, 255, 255, 0.87);
  background: rgba(255, 255, 255, 0.04);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 1rem;
  color: rgba(255, 255, 255, 0.6);
  background: transparent;
  font-weight: 400;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 1rem 1rem;
  color: rgba(255, 255, 255, 0.87);
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #f44435;
}

.p-input-filled .p-multiselect {
  background: rgba(255, 255, 255, 0.06);
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: rgba(255, 255, 255, 0.08);
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: rgba(255, 255, 255, 0.1);
}

p-multiselect.p-multiselect-clearable .p-multiselect-label-container {
  padding-right: 2rem;
}
p-multiselect.p-multiselect-clearable .p-multiselect-clear-icon {
  color: rgba(255, 255, 255, 0.6);
  right: 2.357rem;
}



.p-multiselect .p-multiselect-label, .p-multiselect .p-multiselect-trigger {
  background-image: none;
  background: transparent;
}
.p-multiselect .p-multiselect-label {
  border: 0 none;
}
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA, inset 0 0 0 1px #9FA8DA;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-ink {
  background-color: rgba(159, 168, 218, 0.16);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  background: rgba(255, 255, 255, 0.12);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus.p-highlight {
  background: rgba(159, 168, 218, 0.24);
}
.p-multiselect-panel .p-multiselect-close:focus {
  background: rgba(255, 255, 255, 0.12);
}

.p-input-filled .p-multiselect {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid transparent;
  background: rgba(255, 255, 255, 0.06) no-repeat;
  background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
  background-size: 0 2px, 100% 1px;
  background-position: 50% 100%, 50% 100%;
  background-origin: border-box;
}
.p-input-filled .p-multiselect .p-inputtext {
  background-image: none;
  background-color: transparent;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: rgba(255, 255, 255, 0.08);
  border-color: transparent;
  background-image: linear-gradient(to bottom, #9FA8DA, #9FA8DA), linear-gradient(to bottom, rgba(255, 255, 255, 0.87), rgba(255, 255, 255, 0.87));
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus, .p-input-filled .p-multiselect:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: transparent;
  background-size: 100% 2px, 100% 1px;
}
.p-input-filled .p-multiselect .p-inputtext:enabled:hover, .p-input-filled .p-multiselect .p-inputtext:enabled:focus {
  background-image: none;
  background: transparent;
}
.p-input-filled .p-multiselect .p-multiselect-label:hover {
  background-image: none;
  background: transparent;
}

.p-float-label .p-multiselect-label .p-multiselect-token {
  padding: 0.25rem 1rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.p-input-filled .p-float-label .p-multiselect .p-multiselect-label {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
.p-input-filled .p-float-label .p-inputwrapper-filled .p-multiselect .p-multiselect-label {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
.p-input-filled .p-float-label .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.p-input-filled .p-float-label .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  font-size: 75%;
}
.p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect {
  border-color: transparent;
  background-image: linear-gradient(to bottom, #f44435, #f44435), linear-gradient(to bottom, #f44435, #f44435);
}
.p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled):hover {
  background-image: linear-gradient(to bottom, #f44435, #f44435), linear-gradient(to bottom, #f44435, #f44435);
}
.p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled).p-focus, .p-input-filled p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled).p-inputwrapper-focus {
  box-shadow: none;
  background-image: linear-gradient(to bottom, #f44435, #f44435), linear-gradient(to bottom, #f44435, #f44435);
}

p-multiselect.ng-dirty.ng-invalid .p-multiselect:not(.p-disabled).p-focus {
  box-shadow: inset 0 0 0 1px #f44435, inset 0 0 0 1px #f44435, inset 0 0 0 1px #f44435, inset 0 0 0 1px #f44435;
}
