
.p-sidebar {
    background: #262626;
    color: rgba(255, 255, 255, 0.87);
    border: 0 none;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
  .p-sidebar .p-sidebar-header {
    padding: 1rem;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close,
  .p-sidebar .p-sidebar-header .p-sidebar-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: rgba(255, 255, 255, 0.6);
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
    color: rgba(255, 255, 255, 0.6);
    border-color: transparent;
    background: rgba(255, 255, 255, 0.04);
  }
  .p-sidebar .p-sidebar-header .p-sidebar-close:focus,
  .p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-sidebar .p-sidebar-header + .p-sidebar-content {
    padding-top: 0;
  }
  .p-sidebar .p-sidebar-content {
    padding: 1rem;
  }
  .p-sidebar .p-sidebar-footer {
    padding: 1rem;
  }
  
  .p-component-overlay-enter {
    animation: p-component-overlay-enter-animation 150ms forwards;
 }
 
 .p-component-overlay-leave {
    animation: p-component-overlay-leave-animation 150ms forwards;
 }
 
 @keyframes p-component-overlay-enter-animation {
    from {
       background-color: transparent;
    }
    to {
       background-color: var(--maskbg);
    }
 }
 @keyframes p-component-overlay-leave-animation {
    from {
       background-color: var(--maskbg);
    }
    to {
       background-color: transparent;
    }
 }
 
 .p-sidebar-right {
  top: 0;
  right: 0;
  width: 33% !important;
  height: 100%;
}

.p-sidebar-top { 
  top: 0;
  left: 0;
  width: 100%; 
  height: 50% !important;
}