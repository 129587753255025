.p-tooltip .p-tooltip-text {
  background: rgba(97, 97, 97, 1);
  color: #ffffff;
  padding: 0.5rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.1), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: rgba(97, 97, 97, 0.9);
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  border-left-color: rgba(97, 97, 97, 0.9);
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  border-top-color: rgba(97, 97, 97, 0.9);
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  border-bottom-color: rgba(97, 97, 97, 0.9);
}


.glass-tooltip {
  @apply text-glass-600 text-sm block bg-glass-100 uppercase ml-2 border-gold
}
