.p-tag {
    background: #9FA8DA;
    color: #121212;
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 4px;
  }
  .p-tag.p-tag-success {
    background-color: #C5E1A5;
    color: #212121;
  }
  .p-tag.p-tag-info {
    background-color: #90CAF9;
    color: #212121;
  }
  .p-tag.p-tag-warning {
    background-color: #FFF59D;
    color: #212121;
  }
  .p-tag.p-tag-danger {
    background-color: #EF9A9A;
    color: #212121;
  }
  .p-tag .p-tag-icon {
    margin-right: 0.25rem;
    font-size: 0.75rem;
  }
  .p-tag .p-icon {
    width: 0.75rem;
    height: 0.75rem;
  }