.p-dialog-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.p-dialog-mask.p-component-overlay {
  pointer-events: auto;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}


.p-dialog {
    border-radius: 4px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
    border: 0 none;
  }
  .p-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: hsla(242 85% 18%);
    color: rgba(255, 255, 255, 0.87);
    padding: 1.5rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .p-dialog .p-dialog-header .p-dialog-title {
    font-weight: 500;
    font-size: 1.25rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 2.5rem;
    height: 2.5rem;
    color: rgba(255, 255, 255, 0.6);
    border: 0 none;
    background: transparent;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    margin-right: 0.5rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
    color: rgba(255, 255, 255, 0.6);
    border-color: transparent;
    background: rgba(255, 255, 255, 0.04);
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
    margin-right: 0;
  }
  .p-dialog .p-dialog-content {
    background: hsla(242 85% 18%);
    color: rgba(255, 255, 255, 0.87);
    padding: 0 1.5rem 1.5rem 1.5rem;
  }
  .p-dialog .p-dialog-content:last-of-type {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: hsla(242 85% 18%);
    color: rgba(255, 255, 255, 0.87);
    padding: 1rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    font-size: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
    width: 2rem;
    height: 2rem;
  }
  .p-dialog.p-confirm-dialog .p-confirm-dialog-message {
    margin-left: 1rem;
  }
