.p-metergroup {
  display: flex;
}

.p-metergroup-meters {
  display: flex;
}

.p-metergroup-vertical .p-metergroup-meters {
  flex-direction: column;
}

.p-metergroup-labels {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-metergroup-vertical .p-metergroup-labels {
  align-items: start;
}

.p-metergroup-labels-vertical {
  flex-direction: column;
}

.p-metergroup-label {
  display: inline-flex;
  align-items: center;
}

.p-metergroup-label-marker {
  display: inline-flex;
}

.p-metergroup {
  gap: 1rem;
}
.p-metergroup .p-metergroup-meters {
  background: rgba(159, 168, 218, 0.32);
  border-radius: 4px;
}
.p-metergroup .p-metergroup-meter {
  border: 0 none;
  background: #9FA8DA;
}
.p-metergroup .p-metergroup-labels .p-metergroup-label {
  gap: 0.5rem;
}
.p-metergroup .p-metergroup-labels .p-metergroup-label-marker {
  background: #9FA8DA;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
}
.p-metergroup .p-metergroup-labels .p-metergroup-label-icon {
  width: 1rem;
  height: 1rem;
}
.p-metergroup .p-metergroup-labels.p-metergroup-labels-vertical {
  gap: 0.5rem;
}
.p-metergroup .p-metergroup-labels.p-metergroup-labels-horizontal {
  gap: 1rem;
}
.p-metergroup.p-metergroup-horizontal {
  flex-direction: column;
}
.p-metergroup.p-metergroup-horizontal .p-metergroup-meters {
  height: 0.5rem;
}
.p-metergroup.p-metergroup-horizontal .p-metergroup-meter:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-metergroup.p-metergroup-horizontal .p-metergroup-meter:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.p-metergroup.p-metergroup-vertical {
  flex-direction: row;
}
.p-metergroup.p-metergroup-vertical .p-metergroup-meters {
  width: 0.5rem;
  height: 100%;
}
.p-metergroup.p-metergroup-vertical .p-metergroup-meter:first-of-type {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.p-metergroup.p-metergroup-vertical .p-metergroup-meter:last-of-type {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
