.p-float-label > label {
  left: 1rem;
  color: rgba(255, 255, 255, 0.6);
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #f44435;
}


.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: -0.5rem !important;
  background-color: hsla(242 88% 14%);
  padding: 2px 4px;
  margin-left: -4px;
  margin-top: 0;
}

.p-float-label textarea ~ label {
  margin-top: 0;
}

.p-float-label input:focus ~ label,
.p-float-label .p-inputwrapper-focus ~ label {
  color: #9FA8DA;
}

.p-input-filled .p-float-label .p-inputtext {
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
}
.p-input-filled .p-float-label input:focus ~ label,
.p-input-filled .p-float-label input.p-filled ~ label,
.p-input-filled .p-float-label textarea:focus ~ label,
.p-input-filled .p-float-label textarea.p-filled ~ label,
.p-input-filled .p-float-label .p-inputwrapper-focus ~ label,
.p-input-filled .p-float-label .p-inputwrapper-filled ~ label {
  top: 0.25rem !important;
  margin-top: 0;
  background: transparent;
}

