button.p-button, [type="button"], [type="reset"], [type="submit"] {
  color: white;
  background: hsl(234, 100%, 72%);
  border: 0 none;
  padding: 0.714rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  border-radius: 4px;
}
.p-button:enabled:hover {
  background: hsla(234, 100%, 72%, 0.9);
  color: hsl(0, 0%, 7%);
  border-color: transparent;
}
.p-button:enabled:active {
  background: hsla(234, 100%, 72%, 0.7);
  color: hsl(0, 0%, 7%);
  border-color: transparent;
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: hsl(234, 100%, 72%);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:hover {
  background: hsla(234, 100%, 72%, 0);
  color: hsl(234, 100%, 72%);
  border: 1px solid;
}
.p-button.p-button-outlined:enabled:active {
  background: hsla(234, 100%, 72%, 0.2);
  color: hsl(234, 100%, 72%);
  border: 1px solid;
}
.p-button.p-button-outlined.p-button-plain {
  color: hsla(0, 0%, 100%, 0.6);
  border-color: hsla(0, 0%, 100%, 0.6);
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: hsla(0, 0%, 100%, 0);
  color: hsla(0, 0%, 100%, 0.6);
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: hsla(0, 0%, 100%, 0.2);
  color: hsla(0, 0%, 100%, 0.6);
}
.p-button.p-button-text {
  background-color: transparent;
  color: hsl(234, 100%, 72%);
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: hsla(234, 100%, 72%, 0);
  color: hsl(234, 100%, 72%);
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: hsla(234, 100%, 72%, 0.2);
  color: hsl(234, 100%, 72%);
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: hsla(0, 0%, 100%, 0.6);
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: hsla(0, 0%, 100%, 0);
  color: hsla(0, 0%, 100%, 0.6);
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: hsla(0, 0%, 100%, 0.2);
  color: hsla(0, 0%, 100%, 0.6);
}
.p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-button .p-button-label {
  transition-duration: 0.2s;
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 1rem;
  height: 1rem;
  line-height: 1rem;
  color: hsl(234, 100%, 72%);
  background-color: hsl(0, 0%, 7%);
}
.p-button.p-button-raised {
  box-shadow: 0 3px 1px -2px hsla(0, 0%, 0%, 0.2), 0 2px 2px 0 hsla(0, 0%, 0%, 0.1), 0 1px 5px 0 hsla(0, 0%, 0%, 0.1);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 3rem;
  padding: 0.714rem;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 3rem;
}
.p-button.p-button-sm {
  font-size: 0.875rem;
  padding: 0.62475rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 1.25rem;
  padding: 0.8925rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 3rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary, .p-buttonset.p-button-secondary > .p-button, .p-splitbutton.p-button-secondary > .p-button {
  color: hsl(0, 0%, 7%);
  background: hsl(234, 82%, 75%);
  border: 0 none;
}
.p-button.p-button-secondary:enabled:hover, .p-buttonset.p-button-secondary > .p-button:enabled:hover, .p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: hsla(234, 82%, 75%, 0.9);
  color: hsl(0, 0%, 7%);
  border-color: transparent;
}
.p-button.p-button-secondary:enabled:focus, .p-buttonset.p-button-secondary > .p-button:enabled:focus, .p-splitbutton.p-button-secondary > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-secondary:enabled:active, .p-buttonset.p-button-secondary > .p-button:enabled:active, .p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: hsla(234, 82%, 75%, 0.7);
  color: hsl(0, 0%, 7%);
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-outlined, .p-buttonset.p-button-secondary > .p-button.p-button-outlined, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: hsl(234, 82%, 75%);
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: hsla(234, 82%, 75%, 0);
  color: hsl(234, 82%, 75%);
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: hsla(234, 82%, 75%, 0.2);
  color: hsl(234, 82%, 75%);
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text, .p-buttonset.p-button-secondary > .p-button.p-button-text, .p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: hsl(234, 82%, 75%);
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: hsla(234, 82%, 75%, 0);
  border-color: transparent;
  color: hsl(234, 82%, 75%);
}
.p-button.p-button-secondary.p-button-text:enabled:active, .p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: hsla(234, 82%, 75%, 0.2);
  border-color: transparent;
  color: hsl(234, 82%, 75%);
}

.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button {
  color: hsl(0, 0%, 12%);
  background: hsl(207, 89%, 77%);
  border: 0 none;
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: hsla(207, 89%, 77%, 0.9);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-info:enabled:focus, .p-buttonset.p-button-info > .p-button:enabled:focus, .p-splitbutton.p-button-info > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-info:enabled:active, .p-buttonset.p-button-info > .p-button:enabled:active, .p-splitbutton.p-button-info > .p-button:enabled:active {
  background: hsla(207, 89%, 77%, 0.7);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-info.p-button-outlined, .p-buttonset.p-button-info > .p-button.p-button-outlined, .p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: hsl(207, 89%, 77%);
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: hsla(207, 89%, 77%, 0);
  color: hsl(207, 89%, 77%);
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: hsla(207, 89%, 77%, 0.2);
  color: hsl(207, 89%, 77%);
  border: 1px solid;
}
.p-button.p-button-info.p-button-text, .p-buttonset.p-button-info > .p-button.p-button-text, .p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: hsl(207, 89%, 77%);
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: hsla(207, 89%, 77%, 0);
  border-color: transparent;
  color: hsl(207, 89%, 77%);
}
.p-button.p-button-info.p-button-text:enabled:active, .p-buttonset.p-button-info > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: hsla(207, 89%, 77%, 0.2);
  border-color: transparent;
  color: hsl(207, 89%, 77%);
}

.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
  color: hsl(0, 0%, 12%);
  background: hsl(88, 50%, 76%);
  border: 0 none;
}
.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: hsla(88, 50%, 76%, 0.9);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-success:enabled:focus, .p-buttonset.p-button-success > .p-button:enabled:focus, .p-splitbutton.p-button-success > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-success:enabled:active, .p-buttonset.p-button-success > .p-button:enabled:active, .p-splitbutton.p-button-success > .p-button:enabled:active {
  background: hsla(88, 50%, 76%, 0.7);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-success.p-button-outlined, .p-buttonset.p-button-success > .p-button.p-button-outlined, .p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: hsl(88, 50%, 76%);
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: hsla(88, 50%, 76%, 0);
  color: hsl(88, 50%, 76%);
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: hsla(88, 50%, 76%, 0.2);
  color: hsl(88, 50%, 76%);
  border: 1px solid;
}
.p-button.p-button-success.p-button-text, .p-buttonset.p-button-success > .p-button.p-button-text, .p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: hsl(88, 50%, 76%);
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: hsla(88, 50%, 76%, 0);
  border-color: transparent;
  color: hsl(88, 50%, 76%);
}
.p-button.p-button-success.p-button-text:enabled:active, .p-buttonset.p-button-success > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: hsla(88, 50%, 76%, 0.2);
  border-color: transparent;
  color: hsl(88, 50%, 76%);
}

.p-button.p-button-warning, .p-buttonset.p-button-warning > .p-button, .p-splitbutton.p-button-warning > .p-button {
  color: hsl(0, 0%, 12%);
  background: hsl(54, 100%, 80%);
  border: 0 none;
}
.p-button.p-button-warning:enabled:hover, .p-buttonset.p-button-warning > .p-button:enabled:hover, .p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: hsla(54, 100%, 80%, 0.9);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-warning:enabled:focus, .p-buttonset.p-button-warning > .p-button:enabled:focus, .p-splitbutton.p-button-warning > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-warning:enabled:active, .p-buttonset.p-button-warning > .p-button:enabled:active, .p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: hsla(54, 100%, 80%, 0.7);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-warning.p-button-outlined, .p-buttonset.p-button-warning > .p-button.p-button-outlined, .p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: hsl(54, 100%, 80%);
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: hsla(54, 100%, 80%, 0);
  color: hsl(54, 100%, 80%);
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: hsla(54, 100%, 80%, 0.2);
  color: hsl(54, 100%, 80%);
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text, .p-buttonset.p-button-warning > .p-button.p-button-text, .p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: hsl(54, 100%, 80%);
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: hsla(54, 100%, 80%, 0);
  border-color: transparent;
  color: hsl(54, 100%, 80%);
}
.p-button.p-button-warning.p-button-text:enabled:active, .p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: hsla(54, 100%, 80%, 0.2);
  border-color: transparent;
  color: hsl(54, 100%, 80%);
}

.p-button.p-button-help, .p-buttonset.p-button-help > .p-button, .p-splitbutton.p-button-help > .p-button {
  color: hsl(0, 0%, 12%);
  background: hsl(294, 46%, 71%);
  border: 0 none;
}
.p-button.p-button-help:enabled:hover, .p-buttonset.p-button-help > .p-button:enabled:hover, .p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: hsla(294, 46%, 71%, 0.9);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-help:enabled:focus, .p-buttonset.p-button-help > .p-button:enabled:focus, .p-splitbutton.p-button-help > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-help:enabled:active, .p-buttonset.p-button-help > .p-button:enabled:active, .p-splitbutton.p-button-help > .p-button:enabled:active {
  background: hsla(294, 46%, 71%, 0.7);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-help.p-button-outlined, .p-buttonset.p-button-help > .p-button.p-button-outlined, .p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: hsl(294, 46%, 71%);
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: hsla(294, 46%, 71%, 0);
  color: hsl(294, 46%, 71%);
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: hsla(294, 46%, 71%, 0.2);
  color: hsl(294, 46%, 71%);
  border: 1px solid;
}
.p-button.p-button-help.p-button-text, .p-buttonset.p-button-help > .p-button.p-button-text, .p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: hsl(294, 46%, 71%);
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: hsla(294, 46%, 71%, 0);
  border-color: transparent;
  color: hsl(294, 46%, 71%);
}
.p-button.p-button-help.p-button-text:enabled:active, .p-buttonset.p-button-help > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: hsla(294, 46%, 71%, 0.2);
  border-color: transparent;
  color: hsl(294, 46%, 71%);
}

.p-button.p-button-danger, .p-buttonset.p-button-danger > .p-button, .p-splitbutton.p-button-danger > .p-button {
  color: hsl(0, 0%, 12%);
  background: hsl(340, 82%, 75%);
  border: 0 none;
}
.p-button.p-button-danger:enabled:hover, .p-buttonset.p-button-danger > .p-button:enabled:hover, .p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: hsla(340, 82%, 75%, 0.9);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-danger:enabled:focus, .p-buttonset.p-button-danger > .p-button:enabled:focus, .p-splitbutton.p-button-danger > .p-button:enabled:focus {
  box-shadow: none;
}
.p-button.p-button-danger:enabled:active, .p-buttonset.p-button-danger > .p-button:enabled:active, .p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: hsla(340, 82%, 75%, 0.7);
  color: hsl(0, 0%, 12%);
  border-color: transparent;
}
.p-button.p-button-danger.p-button-outlined, .p-buttonset.p-button-danger > .p-button.p-button-outlined, .p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: hsl(340, 82%, 75%);
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: hsla(340, 82%, 75%, 0);
  color: hsl(340, 82%, 75%);
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: hsla(340, 82%, 75%, 0.2);
  color: hsl(340, 82%, 75%);
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text, .p-buttonset.p-button-danger > .p-button.p-button-text, .p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: hsl(340, 82%, 75%);
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: hsla(340, 82%, 75%, 0);
  border-color: transparent;
  color: hsl(340, 82%, 75%);
}
.p-button.p-button-danger.p-button-text:enabled:active, .p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active, .p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: hsla(340, 82%, 75%, 0.2);
  border-color: transparent;
  color: hsl(340, 82%, 75%);
}

.p-button.p-button-link {
  color: hsl(234, 100%, 72%);
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: hsl(234, 100%, 72%);
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: none;
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: var(--glass-500);
  border-color: transparent;
}
.p-button:disabled {
  background-color: hsla(0, 0%, 100%, 0.1) !important;
  color: hsla(0, 0%, 100%, 0.38) !important;
  opacity: 1;
}
.p-button:disabled.p-button-text {
  background-color: transparent !important;
  color: hsla(0, 0%, 100%, 0.4) !important;
}
.p-button:disabled.p-button-outlined {
  background-color: transparent !important;
  color: hsla(0, 0%, 100%, 0.4) !important;
  border-color: hsla(0, 0%, 100%, 0.4) !important;
}


/* >>> stolen from https://primeng.org/styles-RNJKPVL6.css >>> */

.p-buttonset .p-button:not(:last-child) {
  border-right: 0 none
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0
}

.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.p-buttonset .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.p-buttonset .p-button:focus {
  position: relative;
  z-index: 1
}

/* <<< stolen <<< */
