.p-confirm-popup {
  background: var(--glass-600);
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 1rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid transparent;
  border-color: rgba(38, 38, 38, 0);
  border-bottom-color: #d6d6d6;
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(38, 38, 38, 0);
  border-bottom-color: #d6d6d6;
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: #d6d6d6;
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: #d6d6d6;
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-icon.p-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}
