.p-editor-container .p-editor-toolbar {
    background: #1e1e1e;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .p-editor-container .p-editor-toolbar.ql-snow {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
    stroke: rgba(255, 255, 255, 0.6);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
    fill: rgba(255, 255, 255, 0.6);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
    border: 0 none;
    color: rgba(255, 255, 255, 0.6);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
    stroke: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
    fill: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background: #2b2b2b;
    border: 0 none;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 0;
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(255, 255, 255, 0.04);
  }
  .p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
    padding: 1rem 1rem;
  }
  .p-editor-container .p-editor-content {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .p-editor-container .p-editor-content.ql-snow {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  .p-editor-container .p-editor-content .ql-editor {
    background: #1e1e1e;
    color: rgba(255, 255, 255, 0.87);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .p-editor-container .ql-snow.ql-toolbar button:hover,
  .p-editor-container .ql-snow.ql-toolbar button:focus {
    color: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
    stroke: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
    fill: rgba(255, 255, 255, 0.87);
  }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
    color: #9FA8DA;
  }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
    stroke: #9FA8DA;
  }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
    fill: #9FA8DA;
  }
  .p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
  .p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
    color: #9FA8DA;
  }

  .ql-editor.ql-blank::before {
    color: white !important;
  }

  /* Styles for rendered content */
  .whitespace-pre-wrap {
    ul, ol {
      list-style-position: inside;
      margin: 0.5em 0;
      padding-left: 1em;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    li {
      margin: 0.25em 0;
    }

    a {
      color: #9FA8DA;
      text-decoration: underline;
      
      &:hover {
        color: lighten(#9FA8DA, 10%);
      }
    }
  }