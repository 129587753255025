@import 'components/badge/badge.scss';
@import 'components/button/button.scss';
@import 'components/chips/chips.scss';
@import 'components/confirmpopup/confirmpopup.scss';
@import 'components/d3-milestones/d3-milestones.scss';
@import 'components/datatable/datatable.scss';
@import 'components/datepicker/datepicker.scss';
@import 'components/dialog/dialog.scss';
@import 'components/divider/divider.scss';
@import 'components/fieldSet/fieldset.scss';
@import 'components/floatlabel/floatlabel.scss';
@import 'components/inputswitch/inputswitch.scss';
@import 'components/inputtextarea/inputtextarea.scss';
@import 'components/metergroup/metergroup.scss';
@import 'components/multiselect/multiselect.scss';
@import 'components/progressbar/progressbar.scss';
@import 'components/radiobutton/radiobutton.scss';
@import 'components/selectbutton/selectbutton.scss';
@import 'components/sidebar/sidebar.scss';
@import 'components/splitter/splitter.scss';
@import 'components/steps/steps.scss';
@import 'components/tabview/tabview.scss';
@import 'components/tag/tag.scss';
@import 'components/toast/toast.scss';
@import 'components/tooltip/tooltip.scss';
@import 'components/treetable/treetable.scss';
@import 'components/listbox/listbox.scss';
@import 'components/messages/messages.scss';

:root {
  --glass-100: hsl(247 100% 97%);
  --glass-200: hsl(244 100% 89%);
  --glass-300: hsl(239 100% 82%);
  --glass-400: hsl(233 100% 72%);
  --glass-500: hsl(218 99% 50%);
  --glass-600: hsl(225 81% 46%);
  --glass-700: hsl(231 76% 39%);
  --glass-800: hsl(236 79% 30%);
  --glass-900: hsl(242 100% 21%);
  --gold: hsl(46deg 100% 50%);
  --border-radius: 6px;
  --content-padding: 1.25rem;
  --inline-spacing: 0.5rem;
  --focus-ring: 0 0 0 0.2rem #BFDBFE;
  --maskbg: rgba(0, 0, 0, 0.4);
  --highlight-bg: #EFF6FF;
  --highlight-text-color: #1D4ED8;
  --primary-color: #93c5fd;
  --primary-color-text: #1c2127;
  --surface-ground: #040d19;
  --surface-section: #040d19;
  --surface-card: #071426;
  --surface-overlay: #071426;
  --surface-border: #0b213f;
  --surface-hover: rgba(255, 255, 255, 0.03);
  --surface-0: #040d19;
  --surface-50: #1d2530;
  --surface-100: #363d47;
  --surface-200: #4f565e;
  --surface-300: #686e75;
  --surface-400: #82868c;
  --surface-500: #9b9ea3;
  --surface-600: #b4b6ba;
  --surface-700: #cdcfd1;
  --surface-800: #e6e7e8;
  --surface-900: #ffffff;
  --blue-50: #f5f9ff;
  --blue-100: #d0e1fd;
  --blue-200: #abc9fb;
  --blue-300: #85b2f9;
  --blue-400: #609af8;
  --blue-500: #3b82f6;
  --blue-600: #326fd1;
  --blue-700: #295bac;
  --blue-800: #204887;
  --blue-900: #183462;
  --green-50: #f4fcf7;
  --green-100: #caf1d8;
  --green-200: #a0e6ba;
  --green-300: #76db9b;
  --green-400: #4cd07d;
  --green-500: #22c55e;
  --green-600: #1da750;
  --green-700: #188a42;
  --green-800: #136c34;
  --green-900: #0e4f26;
  --yellow-50: #fefbf3;
  --yellow-100: #faedc4;
  --yellow-200: #f6de95;
  --yellow-300: #f2d066;
  --yellow-400: #eec137;
  --yellow-500: #eab308;
  --yellow-600: #c79807;
  --yellow-700: #a47d06;
  --yellow-800: #816204;
  --yellow-900: #5e4803;
  --cyan-50: #f3fbfd;
  --cyan-100: #c3edf5;
  --cyan-200: #94e0ed;
  --cyan-300: #65d2e4;
  --cyan-400: #35c4dc;
  --cyan-500: #06b6d4;
  --cyan-600: #059bb4;
  --cyan-700: #047f94;
  --cyan-800: #036475;
  --cyan-900: #024955;
  --pink-50: #fef6fa;
  --pink-100: #fad3e7;
  --pink-200: #f7b0d3;
  --pink-300: #f38ec0;
  --pink-400: #f06bac;
  --pink-500: #ec4899;
  --pink-600: #c93d82;
  --pink-700: #a5326b;
  --pink-800: #822854;
  --pink-900: #5e1d3d;
  --indigo-50: #f7f7fe;
  --indigo-100: #dadafc;
  --indigo-200: #bcbdf9;
  --indigo-300: #9ea0f6;
  --indigo-400: #8183f4;
  --indigo-500: #6366f1;
  --indigo-600: #5457cd;
  --indigo-700: #4547a9;
  --indigo-800: #363885;
  --indigo-900: #282960;
  --teal-50: #f3fbfb;
  --teal-100: #c7eeea;
  --teal-200: #9ae0d9;
  --teal-300: #6dd3c8;
  --teal-400: #41c5b7;
  --teal-500: #14b8a6;
  --teal-600: #119c8d;
  --teal-700: #0e8174;
  --teal-800: #0b655b;
  --teal-900: #084a42;
  --orange-50: #fff8f3;
  --orange-100: #feddc7;
  --orange-200: #fcc39b;
  --orange-300: #fba86f;
  --orange-400: #fa8e42;
  --orange-500: #f97316;
  --orange-600: #d46213;
  --orange-700: #ae510f;
  --orange-800: #893f0c;
  --orange-900: #642e09;
  --bluegray-50: #f7f8f9;
  --bluegray-100: #dadee3;
  --bluegray-200: #bcc3cd;
  --bluegray-300: #9fa9b7;
  --bluegray-400: #818ea1;
  --bluegray-500: #64748b;
  --bluegray-600: #556376;
  --bluegray-700: #465161;
  --bluegray-800: #37404c;
  --bluegray-900: #282e38;
  --purple-50: #fbf7ff;
  --purple-100: #ead6fd;
  --purple-200: #dab6fc;
  --purple-300: #c996fa;
  --purple-400: #b975f9;
  --purple-500: #a855f7;
  --purple-600: #8f48d2;
  --purple-700: #763cad;
  --purple-800: #5c2f88;
  --purple-900: #432263;
  --red-50: #fff5f5;
  --red-100: #ffd0ce;
  --red-200: #ffaca7;
  --red-300: #ff8780;
  --red-400: #ff6259;
  --red-500: #ff3d32;
  --red-600: #d9342b;
  --red-700: #b32b23;
  --red-800: #8c221c;
  --red-900: #661814;
  --primary-50: #fafcff;
  --primary-100: #e5f1ff;
  --primary-200: #d1e6fe;
  --primary-300: #bcdbfe;
  --primary-400: #a8d0fd;
  --primary-500: #93c5fd;
  --primary-600: #7da7d7;
  --primary-700: #678ab1;
  --primary-800: #516c8b;
  --primary-900: #3b4f65;
  --gray-50: #e6e7e8;
  --gray-100: #cdcfd1;
  --gray-200: #b4b6ba;
  --gray-300: #9b9ea3;
  --gray-400: #82868c;
  --gray-500: #686e75;
  --gray-600: #4f565e;
  --gray-700: #363d47;
  --gray-800: #1d2530;
  --gray-900: #040d19;
}

:root {
  --p-focus-ring-color: var(--primary-color);
}
