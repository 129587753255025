/*.p-selectbutton .p-button:focus {
    background: #1c1c1c;
    border-color: rgba(255, 255, 255, 0.12);
  }

  .p-selectbutton .p-button:focus.p-highlight {
    background: #262626;
    border-color: rgba(255, 255, 255, 0.12);
  }
  */
  .p-selectbutton .p-button {
    background: #2f2f2f;
    border: 1px solid rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87);
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
    padding: 1rem;
    border-radius: 10px;
    cursor: pointer;
  }
  .p-selectbutton .p-button .p-button-icon-left,
  .p-selectbutton .p-button .p-button-icon-right {
    color: rgba(255, 255, 255, 0.6);
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #373737;
    border-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
    color: rgba(255, 255, 255, 0.6);
  }
  .p-selectbutton .p-button.p-highlight {
    background: #1c1c1c;
    border: 1px solid var(--gold) !important;
    color: rgba(255, 255, 255, 0.87);
  }
  .p-selectbutton .p-button.p-highlight .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight .p-button-icon-right {
    color: rgba(255, 255, 255, 0.6);
  }
  .p-selectbutton .p-button.p-highlight:hover {
    background: #262626;
    border-color: rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87);
  }
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
  .p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
    color: rgba(255, 255, 255, 0.6);
  }

  p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    border-color: #f44435;
  }
