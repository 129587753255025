
  .p-steps .p-steps-item .p-menuitem-link {
    background: transparent;
    transition: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-number {
    color: var(--bluegrey-300);
    border: 1px solid var(--black);
    background: var(--glass-800);
    min-width: 2rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1.143rem;
    z-index: 1;
    border-radius: 50%;
  }
  .p-steps .p-steps-item .p-menuitem-link .p-steps-title {
    margin-top: 0.5rem;
    color: rgba(255, 255, 255, 0.87);
  }
  .p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-number {
    background: var(--glass-500);
    color: var(--gold);
    border: 1px solid var(--gold);
    font-style: italic;
  }
  .p-steps .p-steps-item.p-highlight .p-steps-title {
    font-weight: 500;
    color: rgba(255, 255, 255, 0.87);
  }
  .p-steps .p-steps-item:before {
    content: " ";
    border-top: 1px solid var(--gold);
    width: 100%;
    top: 50%;
    left: 0;
    display: block;
    position: absolute;
    margin-top: -1rem;
  }
