/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;

@import 'styles/glass.scss';

html {
  font-size: 14px;
}
html,
body {
  height: 100%;
  width: 100%;
  @apply bg-glass-900 text-glass-100;
}
body {
  margin: 0;
  font-family: Raleway, 'Helvetica Neue', sans-serif;
  @apply overflow-hidden tracking-widest;
}

@supports (scrollbar-color: auto) {
  * {
    scrollbar-color: hsla(0 0% 100% / 0.5) transparent;
    scrollbar-width: thin;
  }
  *:hover {
    scrollbar-color: var(--gold) transparent;
  }
}

@supports selector(::-webkit-scrollbar) {
  *::-webkit-scrollbar {
    background-color: var(--glass-900);
    width: 8px;
  }
  *::-webkit-scrollbar-thumb {
    background-color: hsla(0 0% 100% / 0.5);
    border-radius: 4px;
    border: 1px solid var(--gold);
  }
  *:hover::-webkit-scrollbar-thumb {
    background-color: var(--gold);
  }
}

/* Prime Component Styles */

.p-icon {
  display: inline-block;
  width: 1rem;
  height: 1rem;
}

.p-tabview-nav-container {
  @apply sticky top-0 z-10
}

.p-tabview-nav-content {
  @apply border-b-[0.5px] border-glass-100 py-2 rounded-t-md sticky top-0;
}

.p-tabview.tabview-nav .p-tabview-nav {
  @apply border-b border-glass-100;
}

.p-tabview .p-tabview-nav {
  @apply flex items-center px-4 text-center basis-28;
}

.p-tabview .p-tabview-nav li {
  @apply flex items-center;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  @apply p-0;
}

/* .p-tabview .p-tabview-nav li.p-highlight {
  @apply min-w-[10rem];
} */

.p-tabview .p-tabview-nav li:not(:last-child) .p-tabview-nav-link {
  @apply px-4 py-1 tracking-widest;
}

.p-tabview .p-tabview-nav li:not(:last-child) .p-tabview-nav-link span:first-child {
  @apply mr-6 text-sm uppercase text-gold;
}

.p-tabview li:not(:last-child) .p-tabview-nav-link span:last-child {
  @apply text-xs;
}

.p-tabview .p-tabview-nav li:not(:nth-last-child(2)) .p-tabview-nav-link {
  @apply border-r-[0.5px] border-glass-100;
}

.p-tabview .p-tabview-nav li:nth-last-child(2) .p-tabview-nav-link span:first-child {
  @apply mr-2;
}

/* DATA Tabs */
.data-tabs .p-tabview-nav-content {
  @apply py-0 border-0 bg-glass-500;
}
.data-tabs.p-tabview .p-tabview-nav {
  @apply px-0 border-0 sticky top-0;
}

.data-tabs.p-tabview .p-tabview-nav {
  @apply flex items-center text-center basis-28;
}

.data-tabs.p-tabview .p-tabview-nav li {
  @apply flex items-center;
}

.data-tabs.p-tabview .p-tabview-nav li a.p-tabview-nav-link {
  @apply py-1 px-6 border-r-[0.5px] border-glass-100;
}

.data-tab.p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-nav-link span:first-child {
  @apply mr-6 text-sm uppercase text-glass-100;
}
.data-tabs.p-tabview .p-tabview-nav li a span.p-tabview-title {
  @apply m-0 text-center text-glass-100;
}

.data-tabs.p-tabview .p-tabview-nav li:not(:last-child) .p-tabview-nav-link span:first-child {
  @apply m-0 text-glass-100;
}

.data-tabs.p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link span:first-child {
  @apply flex items-center text-gold;
}

.data-tabs.p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link {
  @apply flex items-center border-0 bg-glass-800 text-gold;
}

.data-tabs.p-tabview .p-tabview-nav li:has(+ li.p-highlight) a.p-tabview-nav-link {
  /* @apply border-0; */
}

/* ACCORDION */
.p-accordion-header-link {
  @apply w-full flex border-t-[0.5px] py-4 px-2 text-xs border-glass-100;
}

.p-accordion-content {
  @apply p-2 mb-3 rounded bg-glass-800;
}

.p-accordion-toggle-icon {
  /* @apply order-2 text-gold text-3xl font-thin */
  @apply mr-2 text-gold;
}

.p-accordion-content p {
  @apply my-2 leading-5;
}

.p-accordion-content ul {
  @apply list-disc list-inside;
}

.p-accordion-content dl > dt {
  @apply mt-3 font-bold;
}

.p-accordion-content dl dl > dt {
  @apply font-normal;
}

.p-accordion-content dl > dd {
  @apply ml-5;
}

.p-element.p-button {
  vertical-align: baseline;
}
/* INPUTS */

/* hide down-arrow in dropdown when `x` to clear is present */
p-dropdown.p-inputwrapper-filled .p-dropdown-clearable .p-dropdown-trigger {
  visibility: hidden;
}

.p-dropdown.p-disabled {
  opacity: 0.5;
}

.p-dropdown-clear-icon,
.p-dropdown-trigger-icon {
  @apply text-gold;
}

[role='listbox'] {
  @apply p-2 shadow-md text-glass-200 bg-glass-900;
}

.p-dropdown-item {
  @apply p-2 border-b-[0.5px] border-glass-400;
}

.p-dropdown-label.p-inputtext {
  margin-right: 4px;
}

.p-dropdown-items-wrapper {
  @apply max-h-[205px] mt-2 border-[0.5px] border-b-[1.5px] border-glass-700 -left-[1px] relative rounded shadow-md overflow-y-auto;
}

.p-dropdown-items {
  @apply p-2;
}

p-dropdownitem li.p-highlight {
  @apply font-bold text-gold bg-glass-400 border-[0.5px] border-gold;
}

p-dropdownitem li:hover {
  @apply bg-glass-600;
}

.p-dropdown,
.p-inputtextarea,
input[type='text'],
input[type='email'],
select {
  @apply py-[0.5rem] px-2 border-[0.5px] border-b-[1.5px] border-gold text-glass-100 rounded bg-transparent ring-0 outline-none;
}

.field {
  margin-bottom: 0.5rem;
}

label {
  @apply tracking-wider;
}

.add-btn {
  @apply text-glass-600 border-gold bg-glass-700;
}

.event-btn:disabled,
.add-btn:disabled {
  @apply bg-glass-100/50;
}

/* Player */

.single-mode,
.dual-mode {
  @apply bg-glass-900;
}

.video-container-controls .p-dropdown {
  width: 100%;
  @apply border-b-[0.5px];
}

.player-container {
  @apply border-gold;
}

.player-video-dual-display-right .player-container {
  border-color: rgb(86, 154, 169);
}

/* p-checkbox */

.p-checkbox-box {
  @apply w-5 h-5 border-[0.5px] border-gold mr-2 p-[2px];
}

.p-checkbox-icon {
  @apply text-glass-900 bg-gold;
}

/* custom player */
.field > label {
  @apply mb-1 text-xs uppercase;
}

/* select search dropdown */
.p-dropdown-filter-icon {
  @apply right-2 text-glass-800;
}

.p-dropdown-filter-container {
  @apply mt-2 rounded bg-glass-100 text-glass-800;
}

.p-dropdown-filter-container input {
  @apply text-glass-800 border-glass-700;
}

/* PRIME MENU */
.p-menu ul {
  padding: 0.75rem 0.5rem !important;
  @apply bg-glass-900 rounded-lg min-h-[100px] flex flex-col justify-between border-glass-700 border-[0.5px] border-b-[1.5px] py-3 px-2 text-sm font-light tracking-widest text-glass-100;
}

.p-tieredmenu {
  @apply p-3 rounded shadow-md bg-glass-200 text-glass-600;
}

.pi-sign-out {
  margin-right: 0.5rem;
}

/* DIALOG */

.dialogBackdrop {
  background-color: rgba(0, 0, 0, 0.66) !important;
}

/* WATCH PAGE */

.watch-content .p-tabview-panels,
.watch-content .p-tabview-panel {
  @apply flex-1 h-full min-h-0;
}

.player-graphs-content .p-tabview,
.data-tabs .p-tabpanel {
  @apply flex flex-col;
}

.data-tabs .p-tabview {
  @apply flex flex-col;
}

.data-tabs .p-tabview .p-tabview-panel {
  @apply flex-1;
}

app-watch-page {
  @apply flex-1;
}

event-watch {
  @apply h-full;
}

/* InputGroup */
.p-inputgroup {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.p-inputgroup-addon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-inputgroup .p-float-label {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.p-inputgroup .p-inputtext,
.p-fluid .p-inputgroup .p-inputtext,
.p-inputgroup .p-inputwrapper,
.p-inputgroup .p-inputwrapper > .p-component {
  flex: 1 1 auto;
  width: 1%;
}

.p-inputgroup-addon {
  background: #1f2d40;
  color: rgba(255, 255, 255, 0.6);
  border-top: 1px solid #304562;
  border-left: 1px solid #304562;
  border-bottom: 1px solid #304562;
  padding: 0.5rem 0.5rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 1px solid #304562;
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}
@tailwind utilities;

/* >>> ng2-dragula >>> */

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/* <<< ng2-dragula <<< */

.error-banner {
  background-color: hsl(14, 100%, 60%);
  padding: 2px 8px;
  display: table;
}

.warning-banner {
  background-color: hsl(40, 100%, 45%);
  padding: 2px 8px;
  display: table;
}

/* GLASSwatch TabView tooltips */
.watchTooltip > .p-tooltip-text {
  min-width: 24em !important;
}
.watchTooltip > .p-tooltip-text p {
  margin: 0.5rem;
}
