.p-chips:not(.p-disabled):hover .p-chips-multiple-container {
  border-color: #52525b;
}
.p-chips:not(.p-disabled).p-focus .p-chips-multiple-container {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: -1px;
  box-shadow: none;
  border-color: #52525b;
}
.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.75rem;
  outline: 1px solid var(--glass-300);
  gap: 1rem;
  width: 100%;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: rgb(12, 145, 198);
  color: #ffffff;
  font-weight:bold;
  border-radius: 16px;
}
.p-chips .p-chips-multiple-container .p-chips-token.p-focus {
  background: rgb(12, 145, 198);
  color: #ffffff;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  border: 0;
  outline: 0;
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  font-size: 1rem;
  color: #ffffff;
  padding: 0;
  margin: 0;
  background-color: transparent;
  box-shadow: none;
  border-radius: .25;
  width: 100%;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #fca5a5;
}

p-chips.p-chips-clearable .p-inputtext {
  padding-right: 1.75rem;
}
p-chips.p-chips-clearable .p-chips-clear-icon {
  color: #a1a1aa;
  right: 0.75rem;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 0.75rem;
  margin-right: 0.5rem;
  background: #3f3f46;
  color: #ffffff;
  border-radius: 16px;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.75rem;
}
.p-chips.p-component.p-input-wrapper { 
  width: 100%;
}

.p-chip {
  background-color: #3f3f46;
  color: #ffffff;
  border-radius: 16px;
  padding: 0 0.75rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}
.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -0.75rem;
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  border-radius: 6px;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s,
    box-shadow 0.2s,
    outline-color 0.2s;
}
.p-chip .pi-chip-remove-icon:focus-visible {
  outline: 1px solid var(--p-focus-ring-color);
  outline-offset: 2px;
  box-shadow: none;
}
.p-chip .pi-chip-remove-icon:focus {
  outline: 0 none;
}

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 0.25rem;
  gap: 1rem;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  border-radius: 4px;
  margin: 0;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.375rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container:has(.p-chips-token) .p-chips-input-token {
  margin-left: 0.5rem;
}
.p-chips.p-disabled .p-chips-multiple-container {
  opacity: 1;
  background-color: #3f3f46;
}

.p-chip {
  border-radius: 16px;
  padding: 0.25rem 0.75rem;
}
.p-chip .p-chip-text {
  margin-top: 0;
  margin-bottom: 0;
}
.p-chip .p-chip-remove-icon {
  margin-left: 0.375rem;
}
.p-chip:has(.p-chip-remove-icon) {
  padding-right: 0.5rem;
}
.p-chip img {
  margin-left: -0.5rem;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  border-radius: 4px;
  margin-right: 0.25rem;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.375rem;
}

.p-inputwrapper-filled .p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 0.25rem;
}
