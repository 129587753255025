
.p-badge {
    background: #3F51B5;
    color: #ffffff;
    font-size: 0.75rem;
    font-weight: 700;
    min-width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
  }
  .p-badge.p-badge-secondary {
    background-color: #ff4081;
    color: #ffffff;
  }
  .p-badge.p-badge-success {
    background-color: #689F38;
    color: #ffffff;
  }
  .p-badge.p-badge-info {
    background-color: #2196F3;
    color: #ffffff;
  }
  .p-badge.p-badge-warning {
    background-color: #FBC02D;
    color: #212529;
  }
  .p-badge.p-badge-danger {
    background-color: #D32F2F;
    color: #ffffff;
  }
  .p-badge.p-badge-lg {
    font-size: 1.125rem;
    min-width: 2.25rem;
    height: 2.25rem;
    line-height: 2.25rem;
  }
  .p-badge.p-badge-xl {
    font-size: 1.5rem;
    min-width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }