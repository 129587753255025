.p-datatable .p-paginator-top {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: transparent;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid var(--gold);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-datatable .p-datatable-footer {
  background: transparent;
  color: rgba(255, 255, 255, 0.87);
  border: 1px solid var(--gold);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 500;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid var(--gold);
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
  background: var(--glass-900);
  transition: none;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid var(--gold);
  border-width: 0 0 1px 0;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
  background: transparent;
}
.p-datatable .p-sortable-column .p-icon-wrapper {
  display: inline;
}.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: rgba(255, 255, 255, 0.6);
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: #9FA8DA;
  background: rgba(159, 168, 218, 0.16);
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.87);
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: rgba(255, 255, 255, 0.6);
}
.p-datatable .p-sortable-column.p-highlight {
  /* background: #1e1e1e; */
  background: var(--glass-800);
  color: rgba(255, 255, 255, 0.87);
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: rgba(255, 255, 255, 0.87);
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.87);
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: rgba(255, 255, 255, 0.87);
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: transparent;
  color: rgba(255, 255, 255, 0.87);
  transition: none;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid var(--gold);
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(255, 255, 255, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: rgba(255, 255, 255, 0.6);
  border-color: transparent;
  background: rgba(255, 255, 255, 0.04);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr:focus {
  outline: 0.15rem solid transparent;
  outline-offset: 0.15rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: rgba(159, 168, 218, 0.16);
  color: #9FA8DA;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 rgba(159, 168, 218, 0.16);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 rgba(159, 168, 218, 0.16);
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.87);
}
.p-datatable .p-column-resizer-helper {
  background: #9FA8DA;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: hsla(242 88% 14%);
}
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-datatable-table > .p-datatable-tfoot, .p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-thead,
.p-datatable.p-datatable-scrollable > .p-datatable-wrapper > .p-scroller-viewport > .p-scroller > .p-datatable-table > .p-datatable-tfoot {
  background-color: hsla(242 88% 14%);
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead + .p-datatable-tfoot > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td {
  border-width: 0 0 0 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(.p-datatable-tfoot) .p-datatable-tbody > tr:last-child > td:last-child {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: #060441b8;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: rgba(159, 168, 218, 0.16);
  color: #9FA8DA;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: #9FA8DA;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: #9FA8DA;
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}

.p-datatable .p-sortable-column {
  outline: 0 none;
}
.p-datatable .p-sortable-column:focus {
  background-color: rgba(255, 255, 255, 0.03);
}
.p-datatable .p-datatable-tbody > tr {
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr:not(.p-highlight):focus {
  background-color: rgba(255, 255, 255, 0.03);
}

.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #9FA8DA;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #9FA8DA;
}


.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(255, 255, 255, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-menu-button:hover {
  color: rgba(255, 255, 255, 0.6);
  border-color: transparent;
  background: rgba(255, 255, 255, 0.04);
}
.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.6);
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: rgba(159, 168, 218, 0.16);
  color: #9FA8DA;
}
.p-column-filter-menu-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-column-filter-clear-button {
  width: 2.5rem;
  height: 2.5rem;
  color: rgba(255, 255, 255, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-clear-button:hover {
  color: rgba(255, 255, 255, 0.6);
  border-color: transparent;
  background: rgba(255, 255, 255, 0.04);
}
.p-column-filter-clear-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}

.p-column-filter-overlay {
  background: #2b2b2b;
  color: rgba(255, 255, 255, 0.87);
  border: 0 none;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 1rem 1rem;
  border: 0 none;
  color: rgba(255, 255, 255, 0.87);
  background: transparent;
  transition: none;
  border-radius: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: #9FA8DA;
  background: rgba(159, 168, 218, 0.16);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: rgba(255, 255, 255, 0.87);
  background: rgba(255, 255, 255, 0.04);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  margin: 0.5rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
  background: #2b2b2b;
  margin: 0;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 1rem 1rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1rem;
}

.p-paginator {
  /* background: #1e1e1e; */
  background-color: var(--glass-900);
  color: rgba(255, 255, 255, 0.87);
  border: solid #404040;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: none;
  border-radius: 50%;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.04);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 3rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 3rem;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: none;
  border-radius: 50%;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba(159, 168, 218, 0.16);
  border-color: rgba(159, 168, 218, 0.16);
  color: #9FA8DA;
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.04);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.6);
}

.p-paginator .p-paginator-element:focus {
  background: rgba(255, 255, 255, 0.12);
}
.p-paginator .p-paginator-element:focus.p-highlight {
  background: rgba(159, 168, 218, 0.24);
}

