.p-fieldset {
  border: 1px solid rgba(255, 255, 255, 0.12);
  background-color: var(--glass-700);
  color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  height:100%;
  width:100%;
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem;
  margin-left: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.87);
  background-color: var(--glass-400);
  font-weight: 500;
  border-radius: 4px;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem;
  color: rgba(255, 255, 255, 0.87);
  border-radius: 4px;
  transition: none;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
}
.p-fieldset .p-fieldset-content {
  padding: 1rem;
  width:100%;
  height:100%;
}

.p-fieldset .p-fieldset-legend {
  border: 0 none;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  background: rgba(255, 255, 255, 0.12);
}
