.p-listbox {
    background: #1e1e1e;
    color: rgba(255, 255, 255, 0.87);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    transition: background-color 0.2s, border-color 0.2s, color 0.2s, box-shadow 0.2s, background-size 0.2s cubic-bezier(0.64, 0.09, 0.08, 1);
  }
  .p-listbox .p-listbox-header {
    padding: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    color: rgba(255, 255, 255, 0.87);
    background: #1e1e1e;
    margin: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .p-listbox .p-listbox-header .p-listbox-filter {
    padding-right: 2rem;
  }
  .p-listbox .p-listbox-header .p-listbox-filter-icon {
    right: 1rem;
    color: rgba(255, 255, 255, 0.6);
  }
  .p-listbox .p-listbox-header .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-listbox .p-listbox-list {
    padding: 0;
    outline: 0 none;
  }
  .p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 1rem 1rem;
    border: 0 none;
    color: rgba(255, 255, 255, 0.87);
    transition: none;
    border-radius: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item:first-child {
    margin-top: 0;
  }
  .p-listbox .p-listbox-list .p-listbox-item.p-highlight {
    color: #9FA8DA;
    background: rgba(159, 168, 218, 0.16);
  }
  .p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
    margin-right: 0.5rem;
  }
  .p-listbox .p-listbox-list .p-listbox-item-group {
    margin: 0;
    padding: 1rem;
    color: rgba(255, 255, 255, 0.6);
    background: transparent;
    font-weight: 400;
  }
  .p-listbox .p-listbox-list .p-listbox-empty-message {
    padding: 1rem 1rem;
    color: rgba(255, 255, 255, 0.87);
    background: transparent;
  }
  .p-listbox:not(.p-disabled) .p-listbox-item.p-highlight.p-focus {
    background: rgba(159, 168, 218, 0.24);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled).p-focus {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(255, 255, 255, 0.04);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(255, 255, 255, 0.04);
  }
  .p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover.p-focus {
    color: rgba(255, 255, 255, 0.87);
    background: rgba(255, 255, 255, 0.04);
  }
  .p-listbox.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none;
    border-color: #9FA8DA;
  }

  p-listbox.ng-dirty.ng-invalid > .p-listbox {
    border-color: #f44435;
  }